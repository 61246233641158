<template>
  <!-- eslint-disable max-len -->
  <div class="hero-pattern min-h-screen flex flex-col justify-between items-center">
    <header class="pt-9">
      <div class="sm:flex sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <a href="/">
          <img src="logo-parcelex-white.svg" class="h-8" alt="Logo Parcelex"/>
        </a>
        <div class="uppercase tracking-widest font-light text-white">
          Para Negócios
        </div>
      </div>
    </header>
    <div class="flex-shrink-0 p-6 lg:p-0 w-full md:max-w-md">
      <div class="p-6 lg:p-9 lg:pb-8 bg-white rounded-2xl mb-6 shadow-lg">
        <header class="mb-5 text-center">
          <img src="brand-icon-search-circle.svg" class="w-btn mx-auto mb-3" alt="Ícone de lupa'"/>
          <h1 class="font-bold text-xl">Página não encontrada</h1>
        </header>
        <div class="space-y-5">
          <p class="text-gray-500 text-center">
            A página que você tentou acessar não existe ou foi removida.
          </p>
          <a href="/login" class="button button-primary w-full">
            Voltar para o início
          </a>
        </div>
      </div>
    </div>
    <footer class="text-center text-primary-500 pt-4 pb-6">&copy; Parcelex - {{ actualDate }}</footer>
  </div>
</template>

<script>

export default {
  name: '404',
  metaInfo: {
    title: 'Página não encontrada',
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
    actualDate() {
      return new Date().getFullYear();
    },
  },
  methods: {
  },
  mounted() {},
};
</script>
